import { SxProps } from '@mui/material';

export const containerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
};

export const columnsStyle: SxProps = {
  color: '#090909',
  fontSize: '14px',
  fontWeight: 'bold',
};

export const tooltipTxtStyle: SxProps = {
  color: '#fff',
  fontSize: '14px',
};
export const rowsStyle: SxProps = {
  color: '#090909',
  fontSize: '14px',
};
export const rowsStyleBold: SxProps = {
  color: '#090909',
  fontSize: '14px',
  fontWeight: 'bold',
};
export const rowsStyleBoldGreen: SxProps = {
  fontSize: '14px',
  fontWeight: 'bold',
  color: 'green',
};
export const rowsStyleBoldDarkBlue: SxProps = {
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#005391',
};
export const rowsStyleSub: SxProps = {
  color: '#090909',
  fontSize: '12px',
};
export const rowsStyleTag: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  py: '5px',
  px: 1.5,
  bgcolor: '#005391',
  borderRadius: 5,
};
export const rowsStyleTextTag: SxProps = {
  fontSize: '12px',
  color: 'white',
};
export const txt16Bold: SxProps = {
  fontSize: '16px',
  fontWeight: 700,
};
export const txt14ItalicNormal: SxProps = {
  fontSize: '14px',
  fontWeight: 400,
  fontStyle: 'italic',
};
export const rowsStyleTagMore: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  letterSpacing: 3,
  py: 0.5,
  px: 1,
  bgcolor: '#3b98e5',
  borderRadius: 5,
};
export const rowsStyleTextTagMore: SxProps = {
  fontSize: '12px',
  color: 'white',
  fontWeight: 'bold',
};
