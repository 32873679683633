import api from 'clients/api/Api';

import {
  IPartnersManagementResponse,
  ISearchPartners,
} from 'domains/partnersManagement/Partners.domain';
import {
  IRequestParamExportPartnerHistoryRequest,
  IRequestParamExportPartnersRequest,
  IRequestParamExportRequest,
  IRequestParamRequest,
} from 'domains/request';
import { IGetRequestByIdResponse, IRequestManagementResponse } from 'domains/requestManagement';
import { StatusEnum } from 'domains/status';

interface IParams {
  Page: number;
  Size: number;
  UserSector: string;
}

interface IChangePartnerAnswersParams {
  partnerCode: string;
  submitData: {
    userId: string | null;
    answers: {
      questionId: string;
      questionCategory: string;
      questionType: string;
      answer: string[];
    }[];
  };
}

interface IParamsChangeStatus {
  id: string;
  status: StatusEnum;
  userSector: string;
}

const createRequest = async (params: IRequestParamRequest) => {
  const { data } = await api.post('cardio/Request', params);
  return data;
};

const updateRequest = async (id: string, params: IRequestParamRequest) => {
  const { data } = await api.put(`cardio/Request/${id}`, params);
  return data;
};

const getRequestManagement = async (params: IParams) => {
  const { data } = await api.get<IRequestManagementResponse>('cardio/Request/All', {
    params,
  });
  return data;
};

const exportRequests = async (params: IRequestParamExportRequest) => {
  const { data } = await api.get('cardio/Request/Export', {
    params,
  });
  return data;
};

const exportPartners = async (params: IRequestParamExportPartnersRequest) => {
  const { data } = await api.get('cardio/Request/ExportPartners', {
    params,
  });
  return data;
};

const exportPartnersHistory = async (params: IRequestParamExportPartnerHistoryRequest) => {
  const { data } = await api.get('cardio/Request/ExportPartnersHistory', {
    params,
  });
  return data;
};

const changeStatus = async (params: IParamsChangeStatus) => {
  const { data } = await api.put(`cardio/Request/ChangeStatus/${params?.id}`, params);
  return data;
};

const getPartners = async (params: IParams) => {
  const { data } = await api.get<IPartnersManagementResponse>('cardio/Request/Partners', {
    params,
  });
  return data;
};

const getRequestById = async (id: string) => {
  const { data } = await api.get<IGetRequestByIdResponse>(`cardio/Request/${id}`);
  return data;
};

const editValidityRequestManagementDetail = async (
  id: string,
  validityStart: string | null,
  validityEnd: string | null,
) => {
  const { data } = await api.put<any>(`cardio/Request/ChangeValidity/${id}`, {
    validityStart,
    validityEnd,
  });
  return data;
};

const changePartnerAnswers = async (params: IChangePartnerAnswersParams) => {
  const { data } = await api.put(
    `cardio/Request/ChangePartnerAnswers/${params.partnerCode}`,
    params.submitData,
  );
  return data;
};

const changePartnerCoManagers = async params => {
  const { data } = await api.post(`cardio/Request/PartnerCoManagers`, params);
  return data;
};

const deleteRequest = async ({ id, userSector }: { id: string; userSector: string }) => {
  const { data } = await api.delete(`cardio/Request/${id}`, {
    data: {
      userSector,
    },
  });
  return data;
};

const changeEquipmentSerialNumber = async (requestID, payload) => {
  const { data } = await api.put(`cardio/Request/Equipments/${requestID}`, payload);
  return data;
};

const relocationMatrix = async (requestID, payload) => {
  const { data } = await api.put(
    `cardio/Request/Equipments/RelocationMatrix/${requestID}`,
    payload,
  );
  return data;
};

const searchPartnerByDocumentNumber = async (documentNumber: string) => {
  const { data } = await api.get<ISearchPartners>(`cardio/Request/Partner`, {
    params: {
      Search: documentNumber,
    },
  });
  return data;
};

const changeMessage = async (requestID: string, payload: any) => {
  const { data } = await api.put(`cardio/Request/ChangeMessage/${requestID}`, payload);
  return data;
};

const syncMicromedData = async (requestID: string, payload: any) => {
  const { data } = await api.post(`cardio/Request/${requestID}/SyncMicromedData`, payload);
  return data;
};

export const RequestService = {
  changeStatus,
  syncMicromedData,
  changePartnerCoManagers,
  searchPartnerByDocumentNumber,
  createRequest,
  updateRequest,
  relocationMatrix,
  deleteRequest,
  exportRequests,
  getRequestById,
  exportPartnersHistory,
  getPartners,
  exportPartners,
  changePartnerAnswers,
  getRequestManagement,
  editValidityRequestManagementDetail,
  changeEquipmentSerialNumber,
  changeMessage,
};
