import { SolicitationService } from 'services/SolicitationService/SolicitationService';

import { authStore } from 'stores/AuthStore/AuthStore';
import { notificationEvent } from 'stores/Notifications/NotificationsEvents';
import notificationStore from 'stores/Notifications/NotificationsStore';

const execute = async () => {
  try {
    notificationEvent({ loading: true });
    const { sector } = authStore.getState();
    const { pageTabEquipmentReturn, pageTabManagerTransfer } = notificationStore.getState();

    const [equipmentReturn, managerTransfer] = await Promise.all([
      SolicitationService.getAll({
        Page: pageTabEquipmentReturn,
        Size: 10,
        UserSector: sector,
        Type: 'EquipmentReturn',
      }),
      SolicitationService.getAll({
        Page: pageTabManagerTransfer,
        Size: 10,
        UserSector: sector,
        Type: 'ManagerTransfer',
      }),
    ]);

    notificationEvent({ equipmentReturn, managerTransfer });
  } catch (err) {
    console.log(err);
  } finally {
    notificationEvent({ loading: false });
  }
};

export const getNotificationsUseCase = { execute };
