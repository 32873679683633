/* eslint-disable no-else-return */
import { format, Locale } from 'date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';

import formDynamicFormStore from 'stores/DynamicForm/FormDynamicFormStore';

import { IRelocateTypesEnum } from 'domains/partnersManagement/Partners.domain';

type IParamsRelocate = {
  request: any;
  documentNumber: string;
  partnerName: string;
  quantity: string;
  relocateType: IRelocateTypesEnum;
};

const DECIMAL_DIVISOR = 100;

const dateLocales: { [key: string]: Locale } = {
  enUS,
  ptBR,
  esCO: es,
  esDO: es,
  esEC: es,
  esAR: es,
};

const formatStringAutoCapitalize = (text: string): string => {
  // eslint-disable-next-line prefer-regex-literals
  const splitWordsRegex = new RegExp(/([A-Z])/g);

  const result = text.replace(splitWordsRegex, '$1');

  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

const formatStringTruncate = (str: string, num: number): string => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

const customCapitalize = (locale: string | number, date: string): string => {
  if (locale === 'pt-BR') return formatStringAutoCapitalize(date);
  const dateSplit = date.split(' ');
  const capitalizeDate = dateSplit.map(datePart => formatStringAutoCapitalize(datePart));
  return capitalizeDate.join(' ');
};

const formatDate = (
  date: Date,
  options: {
    formatStr: string;
    isCapitalizedPtBr?: boolean;
  },
): string => {
  const { formatStr, isCapitalizedPtBr } = options;
  const browserLocale = navigator.language;
  const formattedDate = format(date, formatStr, {
    locale: dateLocales[browserLocale.replace('-', '') as keyof typeof dateLocales],
  });
  if (!isCapitalizedPtBr && browserLocale === 'pt-BR') return formattedDate;
  return customCapitalize(browserLocale, formattedDate);
};

const formatNumber = (num: number, opt?: Intl.NumberFormatOptions): string => {
  const browserLocale = navigator.language;
  const language = browserLocale === 'en-US' || browserLocale === 'en-ZA' ? 'en' : 'pt';
  return new Intl.NumberFormat(language, opt).format(num);
};

const formatPercentage = (num: number, allowNegative = false): string => {
  const browserLocale = navigator.language;
  const language = browserLocale === 'en-US' || browserLocale === 'en-ZA' ? 'en' : 'pt';
  const number = !allowNegative ? Math.abs(num) / DECIMAL_DIVISOR : num / DECIMAL_DIVISOR;
  return new Intl.NumberFormat(language, {
    style: 'percent',
    maximumFractionDigits: 2,
  })
    .format(number)
    .replace(/\s/g, '');
};

const maskCnpj = (cnpj: string) => {
  if (!cnpj || cnpj === '') return cnpj;
  return cnpj
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

const maskPhone = (value: string) => {
  let str = value;
  str = str?.replace(/\D/g, '');
  str = str?.replace(/^(\d{2})(\d)/g, '($1) $2');
  str = str?.replace(/(\d)(\d{4})$/, '$1-$2');
  return str;
};

const maskCpf = (cpf: string) => {
  const num = cpf?.replace(/\D/g, '');
  const len = num?.length;
  let str = '';
  if (len) {
    if (len <= 6) {
      str = num?.replace(/(\d{3})(\d{1,3})/g, '$1.$2');
    }
    if (len <= 9) {
      str = num?.replace(/(\d{3})(\d{3})(\d{1,3})/g, '$1.$2.$3');
    }
    str = num?.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/g, '$1.$2.$3-$4');
  }
  return str;
};

const maskCep = (value?: string) => {
  let str = value;
  str = str?.replace(/\D/g, '');
  str = str?.replace(/^(\d{5})(\d)/, '$1-$2');
  return str;
};

const parseName = (name: string | null | undefined) => {
  if (!name) return '';
  const arr = name.trim().split(' ');
  if (arr) {
    const firstName = arr[0];
    const lastName = arr[arr.length - 1];
    if (firstName !== lastName) {
      return `${firstName} ${lastName}`;
    }
    return `${firstName}`;
  }
  return name;
};

function dataFormat(dados) {
  const dt = dados.map(d => {
    return `${d.uf}-${d.crm}`;
  });
  return dt;
}

function isCNPJCPFValid(cnpjCpf) {
  const cnpjRegex =
    /^(?:\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}|\d{3}\.\d{3}\.\d{3}-\d{2}|\d{3}\.\d{3}\.\d{3}-\d{5})$/;

  if (cnpjRegex.test(cnpjCpf)) {
    return cnpjCpf.replace(/[^\d]/g, '');
  }

  return cnpjCpf;
}

const formatterDynamicFormSubmitFormData = (sector: string, form: any) => {
  const { gdName, gdSector } = formDynamicFormStore.getState();

  const formattedSubmit = {
    productVersionId: form.productVersionId,
    userSector: sector,
    gdName,
    gdSector,
    answers: [],
    campaigns: [],
  };

  Object.entries(form).forEach(([key, value]) => {
    if (
      key !== 'productVersionId' &&
      key !== 'address' &&
      key !== 'state' &&
      key !== 'city' &&
      key !== 'complement' &&
      key !== 'district'
    ) {
      if (Array.isArray(value) && typeof value[0] === 'object') {
        const res = Formatter.dataFormat(value);
        formattedSubmit.answers.push({
          questionId: key.split('__')[0],
          questionType: key.split('__')[1],
          questionCategory: key.split('__')[2],
          answer: res,
        });
      } else if (key.split('__')[1] === 'Campaign') {
        formattedSubmit.campaigns.push({
          id: key.split('__')[0],
          answer: value,
        });
      } else if (key.split('__')[1] === 'Address') {
        formattedSubmit.answers.push({
          questionId: key.split('__')[0],
          questionType: key.split('__')[1],
          questionCategory: key.split('__')[2],
          answer: [
            `${value}_${form.address}_${form.district}_${form.complement}_${form.state}_${form.city}`,
          ],
        });
      } else if (Array.isArray(value) && !(typeof value[0] === 'object')) {
        formattedSubmit.answers.push({
          questionId: key.split('__')[0],
          questionType: key.split('__')[1],
          questionCategory: key.split('__')[2],
          answer: value,
        });
      } else {
        const res = Formatter.isCNPJCPFValid(value);
        formattedSubmit.answers.push({
          questionId: key.split('__')[0],
          questionType: key.split('__')[1],
          questionCategory: key.split('__')[2],
          answer: Array.isArray(res) ? res : [res],
        });
      }
    }
  });

  return formattedSubmit;
};

const relocateUpdateAnswers = ({
  request,
  documentNumber,
  partnerName,
  quantity,
  relocateType,
}: IParamsRelocate) => {
  const formattedRequest = {
    ...request,
    answers: request.answers.map(row => {
      if (
        row.question.questionType === 'PartnerName' &&
        row.question.questionCategory === 'Client'
      ) {
        const obj = {
          ...row,
          answer: [relocateType === IRelocateTypesEnum.NEW ? '' : partnerName],
        };
        return obj;
      } else if (
        (row.question.questionType.toLowerCase().includes('cnpj') ||
          row.question.questionType.toLowerCase().includes('cpf')) &&
        row.question.questionCategory === 'Client'
      ) {
        const obj = {
          ...row,
          answer: [relocateType === IRelocateTypesEnum.NEW ? '' : documentNumber],
        };
        return obj;
      } else if (
        row.question.questionType === 'EquipmentQty' &&
        row.question.questionCategory === 'Negotiation'
      ) {
        const obj = { ...row, answer: [quantity] };
        return obj;
      } else {
        return row;
      }
    }),
  };
  return formattedRequest;
};

export const Formatter = {
  relocateUpdateAnswers,
  number: formatNumber,
  dataFormat,
  isCNPJCPFValid,
  parseName,
  date: formatDate,
  percentage: formatPercentage,
  capitalize: formatStringAutoCapitalize,
  truncate: formatStringTruncate,
  formatterDynamicFormSubmitFormData,
  maskCnpj,
  maskPhone,
  maskCpf,
  maskCep,
};
