export interface IPartnersManagementResponse {
  data: {
    partners: IPartnersManagement[];
    totalRegistries: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
  };
  hasError: boolean;
  error: string;
}

export interface IPartnersManagement {
  users: {
    id: string;
    name: string;
    sector: string;
    role: string;
    owner: boolean;
  }[];
  partner: {
    cnpj_cpf: string;
    name: string;
  };
  requests: {
    id: string;
    productName: string;
    thumbnail: string;
    validityStart: string;
    validityEnd: string;
    statusId: string;
  }[];
}

export interface ISearchPartners {
  data: {
    cnpj_cpf: string | null;
    name: string | null;
  };
  hasError: boolean;
  error: null | string;
}

export enum IRelocateTypesEnum {
  'NEW' = 1,
  'EXISTING' = 2,
  'MATRIX' = 3,
}
