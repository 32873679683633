/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import { useStore } from 'effector-react';

import {
  AccessAlarmOutlined,
  DeleteForeverOutlined,
  FactoryOutlined,
  NoteAltOutlined,
  PlayCircleOutline,
  SendOutlined,
  ThumbDownAltOutlined,
  ThumbUpAltOutlined,
} from '@mui/icons-material';

import { changeStatusRequestUseCase } from 'useCases/requestManagement/changeStatusRequestUseCase';
import { deleteRequestUseCase } from 'useCases/requestManagement/deleteRequestUseCase';

import { authStore } from 'stores/AuthStore/AuthStore';
import { formDynamicFormEvent } from 'stores/DynamicForm/FormDynamicFormEvents';
import { formDynamicFormInitialState } from 'stores/DynamicForm/FormDynamicFormState';
import { requestDynamicFormEvent } from 'stores/RequestDynamicForm/RequestDynamicFormEvents';
import { requestDynamicFormInitialState } from 'stores/RequestDynamicForm/RequestDynamicFormState';
import { requestManagementEvent } from 'stores/RequestManagement/RequestManagementEvents';

import { StatusEnum, StatusStringEnum } from 'domains/status';

import ConfirmModal from '../../confirmModal/ConfirmModal';
import DropDown from '../../dropdown/DropDown';

function DropDownRequest({ row, handleEditValidity, handleReprove }) {
  const { isAdmin } = useStore(authStore);
  const navigation = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const handleReproveRequest = ({ actualStatus, newStatus, id, observation = '' }) => {
    if (handleReprove) {
      handleReprove(actualStatus, newStatus, id, observation);
    } else {
      changeStatusRequestUseCase.execute({
        id: row.id,
        status: StatusEnum.Reproved,
      });
    }
  };

  const openEditForm = () => {
    requestDynamicFormEvent(requestDynamicFormInitialState);

    formDynamicFormEvent({
      ...formDynamicFormInitialState,
      editForm: row,
    });
    navigation.push(`/app/update-form/${row?.product?.id}`);
  };

  const changeRequestStatus = newStatus => {
    return changeStatusRequestUseCase.execute({
      id: row.id,
      status: newStatus,
    });
  };

  const isRelocationRequest = row.origin === 'Relocation';
  const isRelocationInAnalysis =
    isRelocationRequest && isAdmin && row.statusId === StatusStringEnum.InAnalysis;

  const visibleOptions = {
    edit: row.statusId === StatusStringEnum.InAnalysis && !isRelocationRequest,
    pending: isAdmin && row.statusId !== StatusStringEnum.InAnalysis && !isRelocationRequest,
    approve:
      (isAdmin && row.statusId !== StatusStringEnum.Approved && !isRelocationRequest) ||
      isRelocationInAnalysis,
    reject:
      (isAdmin && row.statusId !== StatusStringEnum.Reproved && !isRelocationRequest) ||
      isRelocationInAnalysis,
    sendMatrix: isAdmin && row.statusId !== StatusStringEnum.SentMatrix && !isRelocationRequest,
    sendSupplier: isAdmin && row.statusId !== StatusStringEnum.SentSuplyer && !isRelocationRequest,
    activate: !isRelocationRequest
      ? !isAdmin
        ? [
            StatusStringEnum.Approved,
            StatusStringEnum.SentSuplyer,
            StatusStringEnum.SentMatrix,
          ].includes(row.statusId)
        : row.statusId !== StatusStringEnum.Active
      : row.statusId === StatusStringEnum.Approved,
    delete: !isRelocationRequest
      ? !isAdmin
        ? [StatusStringEnum.InAnalysis, StatusStringEnum.Reproved].includes(row.statusId)
        : ![StatusStringEnum.Done, StatusStringEnum.Active].includes(row.statusId)
      : row.statusId === StatusStringEnum.Reproved,
  };

  const options = [
    {
      id: '01',
      name: 'Editar solicitação',
      icon: <NoteAltOutlined color="inherit" />,
      action: openEditForm,
      visible: visibleOptions.edit,
    },
    {
      id: StatusEnum.InAnalysis,
      name: 'Pendente',
      icon: <AccessAlarmOutlined color="warning" />,
      action: () => changeRequestStatus(StatusEnum.InAnalysis),
      visible: visibleOptions.pending,
    },
    {
      id: StatusEnum.Approved,
      name: 'Aprovar solicitação',
      icon: <ThumbUpAltOutlined color="success" />,
      action: () => changeRequestStatus(StatusEnum.Approved),
      visible: visibleOptions.approve,
    },
    {
      id: StatusEnum.Reproved,
      name: 'Recusar solicitação',
      icon: <ThumbDownAltOutlined color="error" />,
      action: () =>
        handleReproveRequest({
          actualStatus: row.statusId,
          newStatus: StatusStringEnum.Reproved,
          id: row.id,
          observation: row.observation,
        }),
      visible: visibleOptions.reject,
    },
    {
      id: StatusEnum.SentMatrix,
      name: 'Enviar via matriz',
      icon: <FactoryOutlined color="secondary" />,
      action: () => changeRequestStatus(StatusEnum.SentMatrix),
      visible: visibleOptions.sendMatrix,
    },
    {
      id: StatusEnum.SentSuplyer,
      name: 'Enviar via fornecedor',
      icon: <SendOutlined color="action" />,
      action: () => changeRequestStatus(StatusEnum.SentSuplyer),
      visible: visibleOptions.sendSupplier,
    },
    {
      id: StatusEnum.Active,
      name: 'Ativar parceria',
      icon: <PlayCircleOutline color="primary" />,
      action: () => {
        handleEditValidity(row.id, dayjs().format('DD/MM/YYYY'), '');
        requestManagementEvent({ isOpenEditValidityModal: true });
      },
      visible: visibleOptions.activate,
    },
    {
      id: StatusEnum.Deleted,
      name: 'Excluir solicitação',
      icon: <DeleteForeverOutlined color="error" />,
      action: () => setOpenModal(true),
      visible: visibleOptions.delete,
    },
  ];

  return (
    <>
      <DropDown disabled={row?.statusId === StatusEnum.Deleted} options={options} />
      <ConfirmModal
        open={openModal}
        setOpen={v => setOpenModal(v)}
        text="Deseja realmente excluir essa solicitação?"
        onConfirm={() => deleteRequestUseCase.execute(row.id)}
        isLoading={false}
        title="Atenção"
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
    </>
  );
}

export default DropDownRequest;
