/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
// import dayjs from 'dayjs';
import { useStore } from 'effector-react';

import {
  AssignmentOutlined,
  CloseOutlined,
  CompareArrowsOutlined,
  DeleteForeverOutlined,
  Edit,
  EmojiFlags,
  FlagOutlined,
  FmdGood,
  MedicalServicesOutlined,
  NumbersOutlined,
  PersonAddAltOutlined,
  PersonOutlined,
  RepeatOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';

import ConfirmModal from 'components/ui/confirmModal/ConfirmModal';
import DropDown from 'components/ui/dropdown/DropDown';
import EditValidityModal from 'components/ui/managementRequest/Modals/EditValidityModal/EditValidityModal';

import { editValidityModalPartnersManagementUseCase } from 'useCases/partnersManagement/editValidityPartnersManagementModalUseCase';
import { getPartnersDetailsUseCase } from 'useCases/partnersManagement/getPartnersDetailsUseCase';
import { getPartnersManagementUseCase } from 'useCases/partnersManagement/getPartnersManagementUseCase';
import { changeStatusRequestUseCase } from 'useCases/requestManagement/changeStatusRequestUseCase';

import { Common } from 'utils';

import { authStore } from 'stores/AuthStore/AuthStore';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { StatusEnum } from 'domains/status';

import {
  cardEquipmentDoneStyles,
  cardEquipmentStyles,
  info1,
  info2,
  sectionEquipment,
  subtitle1,
  subtitle2,
  tagButtonDoneStyles,
  tagButtonStyles,
  tagEquipmentReports,
  title,
  titleEquipment,
  txt16normal,
} from '../PartnersManagementPage.styles';
import DrawerSkeleton from './SkeletonLoading';

function DrawerPartnersDetails() {
  const {
    isOpenDrawerDetails,
    selectedRow,
    partnerListMedicines,
    partnerListCRM,
    requestDetails,
    isLoadingDetails,
    isOpenEditValidityModal,
  } = useStore(partnersManagementStore);

  const { role, sector, isAdmin } = useStore(authStore);
  const [openModal, setOpenModal] = useState(false);
  const [dateValidity, setDateValidity] = useState({ id: '', startDate: '', endDate: '' });
  const [selectedId, setSelectedId] = useState('');

  const handleEditValidity = (id: string, startDate: string, endDate: string) => {
    setDateValidity({ id, startDate, endDate });
    partnersManagementEvent({ isOpenEditValidityModal: true });
  };

  function handleCloseModal() {
    partnersManagementEvent({ isOpenEditValidityModal: false });
  }

  const onClose = () => partnersManagementEvent({ isOpenDrawerDetails: false });

  useEffect(() => {
    getPartnersDetailsUseCase.execute();
  }, [selectedRow, isOpenDrawerDetails]);

  const isRep = role === 'REP';
  const isGnOrGd = ['GDGRCE', 'GD', 'GN'].includes(role);
  const filteredPartnerUsers = selectedRow?.users || [];
  const listPartnerUsers = filteredPartnerUsers?.map(obj => obj?.sector) || [];
  const listFirstThirdDigits = filteredPartnerUsers?.map(obj => obj?.sector?.substring(0, 3)) || [];
  const authorizedUsers = isAdmin || isGnOrGd || listPartnerUsers.includes(sector);
  const otherUsers = listFirstThirdDigits.includes(sector?.substring(0, 3));
  const isShowEditButtons = !isRep && (authorizedUsers || (!isGnOrGd && !isAdmin && otherUsers));

  return (
    <Drawer anchor="right" open={isOpenDrawerDetails} onClose={() => onClose()}>
      <Stack
        direction="column"
        flex={1}
        sx={{
          width: '720px',
          backgroundColor: '#F8F9FD',
          paddingBottom: 5,
          borderRadius: '15px 0 0 15px',
        }}
      >
        {isLoadingDetails ? (
          <Box p={4}>
            <DrawerSkeleton />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                borderRadiusTopleft: 18,
                p: 4,
                borderBottom: '1px solid #cecece',
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography fontSize={28} fontWeight="bold">
                  Detalhes da parceria
                </Typography>
                <IconButton onClick={() => onClose()}>
                  <CloseOutlined />
                </IconButton>
              </Stack>
              <Stack
                sx={{
                  marginTop: '26px',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Stack gap={1.5} width="50%">
                  <Typography sx={subtitle1}>Clínica: </Typography>
                  <Typography sx={{ ...subtitle2, fontSize: 14 }}>
                    {selectedRow?.partner?.name || '-'}
                  </Typography>
                </Stack>
                <Stack direction="row" width="50%" justifyContent="space-between">
                  <Box py={1}>
                    <Stack direction="row" gap={1} mb={1}>
                      <FmdGood sx={{ color: '#1D8F5E' }} />
                      <Typography color="#1D8F5E" sx={info1}>
                        Data de início:
                      </Typography>
                      <Typography color="#1D8F5E" sx={info2}>
                        {Common.getEarliestRequestValidityDate(requestDetails)}
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap={1}>
                      <EmojiFlags sx={{ color: '#005391' }} />
                      <Typography color="#005391" sx={info1}>
                        Data final:
                      </Typography>
                      <Typography color="#005391" sx={info2}>
                        {Common.getMostRecentExpiryDateOfRequests(requestDetails)}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
            </Box>
            <Box p={4} bgcolor="white">
              <Box mt={1}>
                <Typography sx={title}>INFORMAÇÕES GERAIS DA PARCERIA</Typography>
                <Divider sx={{ marginTop: '5px' }} />
              </Box>

              {/* InfoManagementCardComponent */}
              <Card sx={{ marginTop: '20px', padding: '10px' }}>
                <Box>
                  <Typography sx={title}>GESTÃO E COGESTÃO</Typography>
                </Box>
                <Stack direction="row" justifyContent="space-between" pt={1} px={1}>
                  <Stack direction="row" gap={2}>
                    <Box>
                      <PersonOutlined sx={{ fontSize: '46px' }} />
                    </Box>
                    <Box>
                      <Typography sx={info2}>GESTOR</Typography>
                      <Typography sx={info1}>
                        {selectedRow?.users
                          ?.filter(obj => obj?.owner)
                          ?.map(obj => (
                            <Typography sx={info1}>{obj?.name}</Typography>
                          ))}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack>
                    {(isAdmin ||
                      role.includes('GN') ||
                      selectedRow?.users
                        ?.filter(obj => obj?.owner)
                        ?.map(obj => obj.sector)
                        .includes(sector)) && (
                      <Button
                        variant="contained"
                        startIcon={<RepeatOutlined />}
                        onClick={() =>
                          partnersManagementEvent({
                            isOpenDrawerPartnerManager: true,
                            isOpenDrawerDetails: false,
                          })
                        }
                      >
                        ALTERAR
                      </Button>
                    )}
                  </Stack>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent="space-between" pt={1} px={1}>
                  <Stack direction="row" gap={2}>
                    <Box>
                      <PersonAddAltOutlined sx={{ fontSize: '46px' }} />
                    </Box>
                    <Box>
                      <Typography sx={info2}>COGESTOR</Typography>
                      <Stack>
                        {selectedRow?.users
                          ?.filter(obj => !obj?.owner)
                          ?.map(obj => (
                            <Typography sx={info1}>{obj?.name}</Typography>
                          ))}
                      </Stack>
                    </Box>
                  </Stack>
                  <Stack>
                    {isShowEditButtons && (
                      <Button
                        onClick={() =>
                          partnersManagementEvent({
                            isOpenDrawerPartnerCoManagers: true,
                            isOpenDrawerDetails: false,
                          })
                        }
                        variant="contained"
                        size="small"
                      >
                        <Edit sx={{ fontSize: 20 }} />
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </Card>

              {/* MedicineEditComponent */}
              <Grid container gap={3} flexWrap="nowrap">
                <Grid item sm={6}>
                  <Card sx={{ border: '1px solid #ddd', marginTop: '20px', padding: '10px' }}>
                    <Stack p={1} direction="row" gap={3} justifyContent="space-between">
                      <Typography sx={title}>PRODUTOS NEGOCIADOS</Typography>
                      {isShowEditButtons && (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ width: 24, height: 28 }}
                          onClick={() =>
                            partnersManagementEvent({
                              isOpenDrawerEditMedicine: true,
                              isOpenDrawerDetails: false,
                            })
                          }
                        >
                          <Edit sx={{ fontSize: 20 }} />
                        </Button>
                      )}
                    </Stack>
                    <Box sx={{ height: 142, overflow: 'auto' }}>
                      {partnerListMedicines?.map(item => (
                        <Stack key={item} direction="row" alignItems="center">
                          <Checkbox defaultChecked checked onChange={() => {}} />
                          <InputLabel sx={txt16normal}>{item}</InputLabel>
                        </Stack>
                      ))}
                    </Box>
                  </Card>
                </Grid>
                {/* CRMEditComponent */}
                <Grid item sm={6}>
                  <Card sx={{ border: '1px solid #ddd', marginTop: '20px', padding: '10px' }}>
                    <Stack p={1} direction="row" gap={3} justifyContent="space-between">
                      <Typography sx={title}>CRMs</Typography>
                      {isShowEditButtons && (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() =>
                            partnersManagementEvent({
                              isOpenDrawerEditCrm: true,
                              isOpenDrawerDetails: false,
                            })
                          }
                        >
                          <Edit sx={{ fontSize: 20 }} />
                        </Button>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      gap={2}
                      minHeight={isShowEditButtons ? '160px' : '148px'}
                      width="100%"
                    >
                      <Box>
                        <MedicalServicesOutlined sx={{ fontSize: '50px' }} />
                      </Box>
                      <Box sx={{ height: 130 }}>
                        <Grid container sx={{ width: '100%', flex: 1, mt: 1, direction: 'row' }}>
                          {partnerListCRM?.map(item => {
                            return (
                              <Grid item xs={6} mb={1}>
                                <Typography sx={{ ...info1, width: 130 }}>{item};</Typography>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>

              {/* DiagnosticCardComponent */}
              <Box mt={5}>
                <Typography sx={title}>DIAGNÓSTICOS</Typography>
                <Divider sx={{ marginTop: '5px' }} />
              </Box>
              {requestDetails
                ?.filter(row => row?.product?.name !== 'Recompra SRA')
                .map(row => {
                  const equipmentQty = Number(row?.equipmentQty || 1);
                  const labelEquipmentQty = `${equipmentQty} ${
                    equipmentQty > 1 ? 'UNIDADES' : 'UNIDADE'
                  }`;
                  const qtyReports = row?.reportsBalance ? Number(row?.reportsBalance) : 0;
                  const labelReportQty = `${qtyReports} ${qtyReports > 1 ? 'LAUDOS' : 'LAUDO'}`;
                  return (
                    <Card
                      key={row.id}
                      sx={row?.statusId === 'Done' ? cardEquipmentDoneStyles : cardEquipmentStyles}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                        mb={2}
                      >
                        <Stack direction="row" gap={2} alignItems="center" ml={1}>
                          {row?.statusId === 'Done' ? (
                            <Box sx={tagButtonDoneStyles}>ENCERRADO</Box>
                          ) : (
                            <Box sx={tagButtonStyles}>{labelEquipmentQty}</Box>
                          )}

                          <Typography sx={titleEquipment}>{row?.product?.name || '-'}</Typography>
                        </Stack>
                        {isShowEditButtons && (
                          <DropDown
                            // eslint-disable-next-line react/no-unstable-nested-components
                            customButton={onPress => (
                              <Button
                                // disabled={row?.statusId === 'Done'}
                                variant="contained"
                                size="small"
                                onClick={onPress}
                              >
                                <Edit sx={{ fontSize: 20 }} />
                              </Button>
                            )}
                            options={[
                              {
                                id: '1',
                                name: 'Alterar vigência',
                                icon: <FlagOutlined color="primary" />,
                                action: () => {
                                  return handleEditValidity(
                                    row.id,
                                    Common.getEarliestRequestValidityDate([row]),
                                    Common.getMostRecentExpiryDateOfRequests([row]),
                                  );
                                },
                                visible: true,
                              },
                              {
                                id: '2',
                                name: 'Encerrar diagnóstico',
                                icon: <DeleteForeverOutlined color="error" />,
                                action: () => {
                                  setOpenModal(true);
                                  setSelectedId(row.id);
                                },
                                visible: true,
                              },
                            ]}
                          />
                        )}
                      </Stack>
                      <Stack direction="row" justifyContent="space-between" px={1} mb={1}>
                        <Box py={1}>
                          <Stack direction="row" gap={1} mb={1}>
                            <FmdGood sx={{ color: '#1D8F5E' }} />
                            <Typography color="#1D8F5E" sx={info1}>
                              Data de início:
                            </Typography>
                            <Typography color="#1D8F5E" sx={info2}>
                              {Common.getEarliestRequestValidityDate([row])}
                            </Typography>
                          </Stack>
                          <Stack direction="row" gap={1}>
                            <EmojiFlags sx={{ color: '#005391' }} />
                            <Typography color="#005391" sx={info1}>
                              Data final:
                            </Typography>
                            <Typography color="#005391" sx={info2}>
                              {Common.getMostRecentExpiryDateOfRequests([row])}
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                      <Divider sx={{ mx: 1 }} />
                      <Stack direction="row" sx={sectionEquipment}>
                        <Stack direction="row" gap={1} alignItems="center" ml={1}>
                          <CompareArrowsOutlined />
                          <Typography sx={info2}>REMANEJAR EQUIPAMENTO</Typography>
                        </Stack>
                        {isShowEditButtons && (
                          <Button
                            variant="contained"
                            disabled={
                              row?.product?.name &&
                              ['sra'].some(keyword =>
                                row.product.name.toLowerCase().includes(keyword.toLowerCase()),
                              )
                            }
                            size="small"
                            onClick={() =>
                              partnersManagementEvent({
                                isOpenDrawerRelocateEquipment: true,
                                relocateDetails: row,
                                isOpenDrawerDetails: false,
                              })
                            }
                          >
                            <Edit sx={{ fontSize: 20 }} />
                          </Button>
                        )}
                      </Stack>
                      <Divider sx={{ mx: 1 }} />
                      <Stack direction="row" sx={sectionEquipment}>
                        <Stack direction="row" gap={1} alignItems="center" ml={1}>
                          <NumbersOutlined />
                          <Typography sx={info2}>NÚMEROS DE SÉRIE</Typography>
                        </Stack>
                        {isShowEditButtons && (
                          <Button
                            variant="contained"
                            size="small"
                            disabled={
                              row?.product?.name &&
                              ['sra'].some(keyword =>
                                row.product.name.toLowerCase().includes(keyword.toLowerCase()),
                              )
                            }
                            onClick={() =>
                              partnersManagementEvent({
                                isOpenDrawerSerialNumber: true,
                                isOpenDrawerDetails: false,
                                detailsDrawerSerialNumber: row,
                              })
                            }
                          >
                            <Edit sx={{ fontSize: 20 }} />
                          </Button>
                        )}
                      </Stack>
                      <Box ml={2}>
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: `repeat(${
                              row?.equipmentItems?.length > 3 ? 2 : 1
                            }, 1fr)`,
                            gap: '2px',
                            height: row?.equipmentItems?.length ? 118 : 'auto',
                            overflow: 'auto',
                          }}
                        >
                          {row?.equipmentItems
                            ?.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber))
                            ?.map(item => (
                              <Typography fontSize="14px">
                                {`${item?.serialNumber || 'N/A'};`}
                              </Typography>
                            ))}
                        </div>
                      </Box>
                      <Divider sx={{ mx: 1 }} />
                      <Stack direction="row" sx={sectionEquipment}>
                        <Stack direction="row" gap={1} alignItems="center" ml={1}>
                          <AssignmentOutlined />
                          <Typography sx={info2}>QUANTIDADE DE LAUDOS</Typography>
                        </Stack>
                        {isShowEditButtons && (
                          <Button
                            variant="contained"
                            size="small"
                            disabled={
                              row?.product?.name &&
                              ['sra', 'bluetooth'].some(keyword =>
                                row.product.name.toLowerCase().includes(keyword.toLowerCase()),
                              )
                            }
                            onClick={() =>
                              partnersManagementEvent({
                                isOpenDrawerDetails: false,
                                isOpenDrawerPartnerReportQty: true,
                                reportQtyNow: labelReportQty,
                                reportQtyRequestId: row.id,
                              })
                            }
                          >
                            <Edit sx={{ fontSize: 20 }} />
                          </Button>
                        )}
                      </Stack>
                      <Box ml={1.3}>
                        <Box sx={tagEquipmentReports}>{labelReportQty}</Box>
                      </Box>
                    </Card>
                  );
                })}
            </Box>
          </>
        )}
      </Stack>
      <EditValidityModal
        isOpenEditValidityModal={isOpenEditValidityModal}
        handleCloseModal={() => handleCloseModal()}
        submitUseCase={editValidityModalPartnersManagementUseCase.execute}
        id={dateValidity?.id}
        startDate={dateValidity?.startDate}
        endDate={dateValidity?.endDate}
        title="Informe a data final de vigência dessa parceria"
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
      <ConfirmModal
        open={openModal}
        setOpen={setOpenModal}
        text="Deseja realmente encerrar esse diagnóstico ?"
        onConfirm={() => {
          changeStatusRequestUseCase
            .execute({
              id: selectedId,
              status: StatusEnum.Done,
            })
            .then(() => {
              setOpenModal(false);
              getPartnersDetailsUseCase.execute();
              getPartnersManagementUseCase.execute();
            });
        }}
        isLoading={false}
        title="Atenção"
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
    </Drawer>
  );
}

export default DrawerPartnersDetails;
