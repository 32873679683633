/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { useStore } from 'effector-react';

import { AddCircleOutlineRounded, ArrowForwardOutlined, DeleteOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import SelectInput from 'components/form/selectInput';
import TextInput from 'components/form/textInput';
import { ButtonBack } from 'components/ui/buttonBack/ButtonBack';
import ConfirmModal from 'components/ui/confirmModal/ConfirmModal';
import { StatusDiagnostic } from 'components/ui/statusDiagnostic';

import {
  getCnpjInfoUseCase,
  loadCNPJInfoInputsUseCase,
  loadIdentificationInputsUseCase,
} from 'useCases/dynamicForm';
import { loadEditFormUseCase } from 'useCases/dynamicForm/loadEditFormUseCase';
import { loadSearchUserInfoUseCase } from 'useCases/dynamicForm/loadSearchUserInfoUseCase';
import { loadSectorNameInputUseCase } from 'useCases/dynamicForm/loadSectorNameInputUseCase';
import { updateFormDynamicFormUseCase } from 'useCases/dynamicForm/updateDynamicFormUseCase';

import { ViaCEPService } from 'services/ViaCEP/ViaCEPService';

import useResponsive from 'hooks/useResponsive';

import { Common } from 'utils';
import { BrazilState } from 'utils/uf';

import { authStore } from 'stores/AuthStore/AuthStore';
import { formDynamicFormEvent } from 'stores/DynamicForm/FormDynamicFormEvents';
import formDynamicFormStore from 'stores/DynamicForm/FormDynamicFormStore';

import { IAtuhEnum } from 'domains/auth/AuthDomain';

import { validateCep, validateRadioForm } from '../DynamicFormValidators';
import { getComponentAndProps, getComponentRulesValidation } from '../HelperDynamicForm';

type InputProps = {
  maxLength?: number;
  onlyWords?: boolean;
  mask?: 'CPF' | 'CNPJ' | 'CEP' | 'PHONE' | 'CPF_CNPJ';
};

export type TextInputProps = InputProps & {
  label: string;
  name: string;
  type: 'text' | 'number' | 'tel';
  error: boolean;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export type SelectInputProps = InputProps & {
  label: string;
  name: string;
  type: 'text' | 'number' | 'tel';
  error: boolean;
  value: string;
  helperText?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

function DynamicFormEdit({ data }: any) {
  const navigation = useHistory();
  const [inputSectorName, setInputSectorName] = useState('');
  const { role } = useStore(authStore);
  const isDesktop = useResponsive({ query: 'up', start: 'lg' });
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    reset,
    watch,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
  });
  const { isLoading, openModal, openModalError, errorMessage, cnpjInfo, relocateType } =
    useStore(formDynamicFormStore);
  const [reseted, setReseted] = useState(false);
  const [isErrorUser, setIsErrorUser] = useState(false);
  const setOpenModal = (val: boolean) => formDynamicFormEvent({ openModal: val });
  const setOpenModalError = (val: boolean) => formDynamicFormEvent({ openModalError: val });

  async function handleCepChange(cep: string, complement = '') {
    try {
      const getCepVal = await ViaCEPService.getAddressByCep(cep);
      setValue('state', getCepVal.uf);
      setValue('city', getCepVal.localidade);
      setValue('address', getCepVal.logradouro);
      setValue('district', getCepVal.bairro);
      if (complement && complement !== 'undefined') setValue('complement', complement);
    } catch (e) {
      // console.log(e)
    }
  }

  const categoryName = {
    Identification: 'INFORMAÇÕES DO SOLICITANTE',
    Client: 'INFORMAÇÕES DA CLÍNICA/MÉDICO',
    Negotiation: 'INFORMAÇÕES DA PARCERIA',
    Shipping: 'INFORMAÇÕES DE ENTREGA (SOLICITANTE EMS)',
  };

  const questionCategoryList = ['Identification', 'Client', 'Negotiation', 'Shipping'];

  const campaignsWithSectionInfo = data?.campaigns.filter(campaign => campaign.status === false);

  const sectorInputValueStr = watch(inputSectorName);

  useEffect(() => {
    loadEditFormUseCase
      .execute({
        data,
        cbReset: setReseted,
        cbSetValue: reset,
        getCepInfo: handleCepChange,
      })
      .then(() => {
        clearErrors();
      });
  }, [reset]);

  useEffect(() => {
    if (sectorInputValueStr && sectorInputValueStr.length > 7) {
      loadSearchUserInfoUseCase.execute({
        searchSector: sectorInputValueStr,
        data,
        cbReset: setReseted,
        cbSetValue: setValue,
        questionCategoryList,
        setIsErrorUser,
      });
    }
  }, [sectorInputValueStr]);

  useEffect(() => {
    loadSectorNameInputUseCase.execute({
      cbSetValue: setInputSectorName,
      data,
      questionCategoryList,
    });
    if (role !== IAtuhEnum.ADMIN) {
      loadIdentificationInputsUseCase.execute({
        data,
        cbReset: setReseted,
        cbSetValue: setValue,
        questionCategoryList,
      });
    }
  }, [data]);

  useEffect(() => {
    loadCNPJInfoInputsUseCase.execute({
      data,
      cbReset: setReseted,
      cbSetValue: setValue,
      questionCategoryList,
    });
  }, [cnpjInfo]);

  return (
    <Stack>
      <Stack width="100px">
        <ButtonBack text="Voltar" onBack={() => navigation.goBack()} />
      </Stack>

      <Stack mt={3} direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="body1" fontWeight="700">
          {`Solicitação ${data?.name}`}
        </Typography>
        {!!relocateType && <StatusDiagnostic statusCode="Relocate" />}
      </Stack>

      <Controller
        name="productVersionId"
        control={control}
        defaultValue={data.productVersionId}
        render={({ field: { name } }) => {
          return <TextField name={name} hidden aria-hidden hiddenLabel sx={{ display: 'none' }} />;
        }}
      />
      {questionCategoryList.map(section => {
        const val = data?.productQuestions
          .sort((a, b) => a.order - b.order)
          .filter(question => question.question.questionCategory === section);
        return (
          <>
            {val?.length > 0 && (
              <>
                <Typography variant="caption" fontWeight="700" mt={4} mb={1}>
                  {categoryName[section]}
                </Typography>
                <Divider style={{ marginBottom: '10px' }} />
              </>
            )}

            {!reseted && (
              <Grid container gap={3}>
                {val?.map(item => {
                  const { component: Component, props } = getComponentAndProps(item);
                  const { ruleValidation } = getComponentRulesValidation(item);
                  if (Component === TextInput) {
                    const relocationValidate =
                      !!relocateType &&
                      item.question.questionType === 'EquipmentQty' &&
                      item.question.questionCategory === 'Negotiation';
                    const mrpaManualValidate =
                      data?.name?.toLowerCase().includes('mrpa manual') &&
                      item?.question?.description?.toLowerCase().includes('quantidade de laudos');
                    const isDisabledInput = relocationValidate || mrpaManualValidate;
                    return (
                      <Grid item xs={12} sm={5} md={5.5} lg={5.5} key={item.question.id}>
                        {/* Use question.id as the unique identifier for the Controller */}
                        <Controller
                          name={`${item.question.id}__${item.question.questionType}__${item.question.questionCategory}`}
                          control={control}
                          rules={{ validate: ruleValidation }}
                          render={({ field: { onChange, value, name } }) => {
                            // Separate rendering logic based on the component type
                            return (
                              <TextInput
                                key={item.question.id}
                                value={value}
                                disabled={isDisabledInput}
                                onChange={e => onChange(e.target.value)}
                                name={name}
                                {...(props as TextInputProps)}
                                error={!!errors[name]}
                                onBlur={e => {
                                  const questionTypesCNPJ = ['CNPJ', 'CPF_CNPJ'];
                                  const isInputCNPJ = questionTypesCNPJ.includes(
                                    item.question.questionType,
                                  );
                                  if (isInputCNPJ) {
                                    getCnpjInfoUseCase.execute(e.target.value);
                                  }
                                }}
                                helperText={
                                  errors[name] ? (
                                    <Typography fontSize="12px">
                                      {(errors[name]?.message as any) ?? ''}
                                    </Typography>
                                  ) : (
                                    ''
                                  )
                                }
                              />
                            );
                          }}
                        />
                      </Grid>
                    );
                  }
                  if (Component === 'Address')
                    return (
                      <Grid container xs={12} gap={3} key={item.question.id}>
                        {/* Use question.id as the unique identifier for the Controller */}
                        <Grid item xs={12} sm={5} md={5.5} lg={5.5}>
                          <Controller
                            name={`${item.question.id}__${item.question.questionType}__${item.question.questionCategory}`}
                            control={control}
                            rules={{ validate: validateCep }}
                            render={({ field: { onChange, value, name } }) => {
                              // Separate rendering logic based on the component type
                              return (
                                <TextInput
                                  label="CEP"
                                  mask="CEP"
                                  placeholder="00000-000"
                                  key={`${item.question.id}_cep`}
                                  value={value}
                                  maxLength={9}
                                  onChange={e => {
                                    if (e.target.value.length === 9) {
                                      handleCepChange(e.target.value);
                                    }
                                    onChange(e.target.value);
                                  }}
                                  name={name}
                                  {...(props as TextInputProps)}
                                  error={!!errors[name]}
                                  helperText={
                                    errors[name] ? (
                                      <Typography fontSize="12px">
                                        {(errors[name]?.message as any) ?? ''}
                                      </Typography>
                                    ) : (
                                      ''
                                    )
                                  }
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5.5} lg={5.5}>
                          <Controller
                            name="address"
                            control={control}
                            render={({ field: { onChange, value, name } }) => {
                              // Separate rendering logic based on the component type
                              return (
                                <TextInput
                                  label="Endereço"
                                  key={`address_${item.question.id}`}
                                  value={value}
                                  onChange={e => onChange(e.target.value)}
                                  name={name}
                                  {...(props as TextInputProps)}
                                  error={!!errors[name]}
                                  helperText={
                                    errors[name] ? (
                                      <Typography fontSize="12px">
                                        {(errors[name]?.message as any) ?? ''}
                                      </Typography>
                                    ) : (
                                      ''
                                    )
                                  }
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5.5} lg={5.5}>
                          <Controller
                            name="district"
                            control={control}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <TextInput
                                  label="Bairro"
                                  key={`district_${item.question.id}`}
                                  value={value}
                                  onChange={e => onChange(e.target.value)}
                                  name={name}
                                  {...(props as TextInputProps)}
                                  error={!!errors[name]}
                                  helperText={
                                    errors[name] ? (
                                      <Typography fontSize="12px">
                                        {(errors[name]?.message as any) ?? ''}
                                      </Typography>
                                    ) : (
                                      ''
                                    )
                                  }
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5.5} lg={5.5}>
                          <Controller
                            name="complement"
                            control={control}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <TextInput
                                  label="Complemento"
                                  key={`complement_${item.question.id}`}
                                  value={value}
                                  onChange={e => onChange(e.target.value)}
                                  name={name}
                                  {...(props as TextInputProps)}
                                  error={!!errors[name]}
                                  helperText={
                                    errors[name] ? (
                                      <Typography fontSize="12px">
                                        {(errors[name]?.message as any) ?? ''}
                                      </Typography>
                                    ) : (
                                      ''
                                    )
                                  }
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5.5} lg={5.5}>
                          <Controller
                            name="state"
                            control={control}
                            render={({ field: { onChange, value, name } }) => {
                              // Separate rendering logic based on the component type
                              return (
                                <TextInput
                                  label="Estado"
                                  disabled
                                  key={`state_${item.question.id}`}
                                  value={value}
                                  onChange={e => onChange(e.target.value)}
                                  name={name}
                                  {...(props as TextInputProps)}
                                  error={!!errors[name]}
                                  helperText={
                                    errors[name] ? (
                                      <Typography fontSize="12px">
                                        {(errors[name]?.message as any) ?? ''}
                                      </Typography>
                                    ) : (
                                      ''
                                    )
                                  }
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5.5} lg={5.5}>
                          <Controller
                            name="city"
                            control={control}
                            render={({ field: { onChange, value, name } }) => {
                              // Separate rendering logic based on the component type
                              return (
                                <TextInput
                                  label="Cidade"
                                  disabled
                                  key={`city_${item.question.id}`}
                                  value={value}
                                  onChange={e => onChange(e.target.value)}
                                  name={name}
                                  {...(props as TextInputProps)}
                                  error={!!errors[name]}
                                  helperText={
                                    errors[name] ? (
                                      <Typography fontSize="12px">
                                        {(errors[name]?.message as any) ?? ''}
                                      </Typography>
                                    ) : (
                                      ''
                                    )
                                  }
                                />
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    );

                  if (Component === SelectInput)
                    return (
                      <Grid item xs={12} sm={5} md={5.5} lg={5.5}>
                        <Controller
                          name={`${item.question.id}__${item.question.questionType}__${item.question.questionCategory}`}
                          control={control}
                          rules={{ validate: ruleValidation }}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <SelectInput
                                options={item.options}
                                value={value}
                                onChange={e => onChange(e?.target?.value)}
                                name={name}
                                {...(props as SelectInputProps)}
                                error={!!errors[name]}
                                helperText={(errors[name]?.message as any) ?? ''}
                              />
                            );
                          }}
                        />
                      </Grid>
                    );
                  if (Component === 'ufCrm')
                    return (
                      <Grid item xs={12}>
                        <Controller
                          name={`${item.question.id}__${item.question.questionType}__${item.question.questionCategory}`}
                          control={control}
                          defaultValue={[{ id: Math.random() * 999, crm: '', uf: '' }]}
                          rules={{ validate: ruleValidation }}
                          render={({ field: { value, onChange, name } }) => {
                            return (
                              <>
                                {value?.map((row, index) => (
                                  <Grid container mt={0} gap={0} key={row.id}>
                                    <Grid item xs={12} sm={5.5} md={5.5} lg={6} mt={2}>
                                      <Grid
                                        container
                                        gap={3}
                                        flexWrap={isDesktop ? 'nowrap' : 'wrap'}
                                      >
                                        <Grid item xs={12} sm={7} md={7} lg={8}>
                                          <TextInput
                                            label="CRM Envolvido na negociação"
                                            value={value[index]?.crm}
                                            type="number"
                                            name={`${'crm'}-${index}`}
                                            onChange={e => {
                                              const v = e.target.value;
                                              if (v?.length > 7) return;
                                              const newArr = [...value];
                                              newArr[index].crm = v;
                                              onChange(newArr);
                                            }}
                                            onBlur={() => {
                                              const newArr = [...value];
                                              newArr[index].crm = newArr[index].crm?.padStart(
                                                7,
                                                '0',
                                              );
                                              onChange(newArr);
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={3} lg={4}>
                                          <SelectInput
                                            label="UF"
                                            value={value[index]?.uf}
                                            options={BrazilState?.map(obj => {
                                              return {
                                                label: obj.sigla,
                                                name: obj.sigla,
                                                value: obj.sigla,
                                                id: '',
                                                order: 0,
                                              };
                                            })}
                                            name={`${'uf'}-${index}`}
                                            onChange={e => {
                                              const newArr = [...value];
                                              newArr[index].uf = e.target.value;
                                              onChange(newArr);
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={5.5} md={5.5} lg={6} mt={2}>
                                      <Stack direction="row" mt={3.5} ml={1}>
                                        {index === value.length - 1 && (
                                          <IconButton
                                            color="primary"
                                            onClick={() =>
                                              onChange([
                                                ...value,
                                                { id: Math.random() * 999, crm: '', uf: '' },
                                              ])
                                            }
                                          >
                                            <AddCircleOutlineRounded
                                              color="success"
                                              style={{ fontSize: 24 }}
                                            />
                                          </IconButton>
                                        )}
                                        {[...value].length > 1 && (
                                          <IconButton
                                            color="error"
                                            onClick={() =>
                                              onChange([...value].filter(it => it.id !== row.id))
                                            }
                                          >
                                            <DeleteOutline style={{ fontSize: 24 }} />
                                          </IconButton>
                                        )}
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                ))}
                                <Stack>
                                  {!!errors[name] && (
                                    <Typography fontSize="12px" color="red">
                                      {(errors[name]?.message as any) ?? ''}
                                    </Typography>
                                  )}
                                </Stack>
                              </>
                            );
                          }}
                        />
                      </Grid>
                    );

                  if (
                    (Component === 'medicine' || Component === 'investments') &&
                    item.options.length > 0
                  ) {
                    return (
                      <Grid item mt={0} xs={12}>
                        <Stack direction="column">
                          <Box style={{ display: 'inline-block' }}>
                            <Typography component="span" fontSize={14}>
                              {item.question.description}
                            </Typography>
                            <Controller
                              control={control}
                              name={`${item.question.id}__${item.question.questionType}__${item.question.questionCategory}`}
                              rules={{ validate: ruleValidation }}
                              defaultValue={[]}
                              render={({ field }) => (
                                <FormGroup>
                                  {item.options &&
                                    item.options.sort(Common.sortAlphabeticMap).map(obj => {
                                      const isChecked = field.value?.includes(obj.id);
                                      return (
                                        <FormControlLabel
                                          key={obj.id}
                                          className="width-fit-content"
                                          control={
                                            <Checkbox
                                              name={obj.id}
                                              checked={isChecked}
                                              onChange={e => {
                                                const { checked } = e.target;
                                                setError(field.name, {
                                                  type: 'custom',
                                                  message: '* Selecione ao menos uma opção',
                                                });
                                                if (checked) {
                                                  field.onChange([...field.value, obj.id]);
                                                } else {
                                                  field.onChange(
                                                    field.value.filter(id => id !== obj.id),
                                                  );
                                                }
                                              }}
                                            />
                                          }
                                          label={<Typography fontSize={14}>{obj.name}</Typography>}
                                        />
                                      );
                                    })}
                                  <Stack>
                                    <Stack>
                                      {errors[field.name] && (
                                        <Typography fontSize="12px" color="red">
                                          * Selecione ao menos uma opção
                                        </Typography>
                                      )}
                                    </Stack>
                                  </Stack>
                                </FormGroup>
                              )}
                            />
                          </Box>
                        </Stack>
                      </Grid>
                    );
                  }
                  if (Component === 'radiobutton')
                    return (
                      <Grid item mt={0} xs={12}>
                        <Stack direction="column">
                          <Box style={{ display: 'inline-block' }}>
                            <Typography component="span" fontSize={14}>
                              {item.question.description}
                            </Typography>
                            <Controller
                              control={control}
                              name={`${item.question.id}__${item.question.questionType}__${item.question.questionCategory}`}
                              rules={{ validate: validateRadioForm }}
                              defaultValue={[]}
                              render={({ field: { name, onChange, value } }) => (
                                <FormGroup>
                                  {item.options &&
                                    item.options.map(obj => {
                                      const isChecked = value === obj.id;
                                      const handleChange = () => {
                                        onChange(isChecked ? '' : obj.id);
                                      };
                                      return (
                                        <FormControlLabel
                                          className="width-fit-content"
                                          key={obj.id}
                                          control={
                                            <Radio
                                              name={name}
                                              checked={isChecked}
                                              value={obj.id}
                                              onChange={handleChange}
                                            />
                                          }
                                          label={<Typography fontSize={14}>{obj.name}</Typography>}
                                        />
                                      );
                                    })}
                                  {!!errors[name] && (
                                    <Typography fontSize="12px" color="red">
                                      {(errors[name]?.message as any) ?? ''}
                                    </Typography>
                                  )}
                                </FormGroup>
                              )}
                            />
                          </Box>
                        </Stack>
                      </Grid>
                    );

                  return null;
                })}
              </Grid>
            )}
          </>
        );
      })}

      {campaignsWithSectionInfo?.length > 0 && (
        <Grid item xs={12} mt={2}>
          <Typography variant="caption" fontWeight="700" mt={4} mb={0}>
            INFORMAÇÕES DE CAMPANHA
          </Typography>
          <Divider style={{ marginBottom: '10px' }} />
        </Grid>
      )}

      {campaignsWithSectionInfo?.map(campaign => {
        return (
          <Grid item mt={0} xs={12}>
            <Stack direction="column">
              <Box style={{ display: 'inline-block' }}>
                <Typography component="span" fontSize={14}>
                  {campaign.name}
                </Typography>
                <Controller
                  control={control}
                  name={`${campaign.id}__Campaign`}
                  rules={{ validate: validateRadioForm }}
                  defaultValue={[]}
                  render={({ field: { name, onChange, value } }) => (
                    <FormGroup style={{ width: '50%' }}>
                      {[
                        {
                          description: 'Sim',
                          order: 1,
                        },
                        {
                          description: 'Não',
                          order: 2,
                        },
                      ].map(obj => {
                        const isChecked = value === obj.description;
                        const handleChange = () => {
                          onChange(isChecked ? '' : obj.description);
                        };
                        return (
                          <FormControlLabel
                            className="width-fit-content"
                            control={
                              <Radio
                                name={name}
                                checked={isChecked}
                                value={obj.description}
                                onChange={handleChange}
                              />
                            }
                            label={<Typography fontSize={14}>{obj.description}</Typography>}
                          />
                        );
                      })}
                      {!!errors[name] && (
                        <Typography fontSize="12px" color="red">
                          {(errors[name]?.message as any) ?? ''}
                        </Typography>
                      )}
                    </FormGroup>
                  )}
                />
              </Box>
            </Stack>
          </Grid>
        );
      })}

      <Stack direction="row" justifyContent="flex-end" mt={5} gap={2}>
        <Button
          variant="contained"
          color="inherit"
          sx={{ width: 250 }}
          onClick={() => navigation.goBack()}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          id="submit-button-form"
          className="submit-button-form"
          color="primary"
          disabled={!isValid || isErrorUser}
          sx={{ width: 250 }}
          onClick={() => setOpenModal(true)}
          endIcon={!isLoading && <ArrowForwardOutlined />}
        >
          {isLoading ? <CircularProgress size="25px" /> : 'EDITAR SOLICITAÇÃO'}
        </Button>
      </Stack>
      <ConfirmModal
        open={openModal}
        setOpen={v => {
          setOpenModal(v);
        }}
        confirmText="Confirmar"
        title="Foram realizadas alterações em sua solicitação!"
        text="Você poderá seguir com as alterações até que a mesma seja aprovada!"
        declineText="Cancelar"
        isLoading={isLoading}
        onConfirm={handleSubmit(updateFormDynamicFormUseCase.execute)}
      />
      <ConfirmModal
        open={openModalError}
        setOpen={v => {
          setOpenModalError(v);
        }}
        confirmText="OK, ENTENDI"
        title="Esta solicitação não pode ser editada"
        text={errorMessage || 'Ocorreu um erro, tente novamente mais tarde'}
        onConfirm={() => {
          setOpenModalError(false);
          window.location.replace('/app/new-request');
        }}
      />
    </Stack>
  );
}

export default DynamicFormEdit;
