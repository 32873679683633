/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useStore } from 'effector-react';

import { ArrowForwardOutlined, CloseOutlined, SearchOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import TextInput from 'components/form/textInput';
import { ButtonBack } from 'components/ui/buttonBack/ButtonBack';
import ConfirmModal from 'components/ui/confirmModal/ConfirmModal';

import { relocationMatrixUseCase } from 'useCases/partnersManagement/relocationMatrixUseCase';
import { searchPartnerByDocumentUseCase } from 'useCases/partnersManagement/searchPartnerByDocumentUseCase';

import { Formatter } from 'utils';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { formDynamicFormEvent } from 'stores/DynamicForm/FormDynamicFormEvents';
import { formDynamicFormInitialState } from 'stores/DynamicForm/FormDynamicFormState';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';
import { requestDynamicFormEvent } from 'stores/RequestDynamicForm/RequestDynamicFormEvents';
import { requestDynamicFormInitialState } from 'stores/RequestDynamicForm/RequestDynamicFormState';

import { IRelocateTypesEnum } from 'domains/partnersManagement/Partners.domain';

function DrawerRelocateEquipment() {
  const { isOpenDrawerRelocateEquipment, relocateDetails, isLoadingRelocate, searchPartnerResult } =
    useStore(partnersManagementStore);

  const [selectedRelocate, setSelectedRelocate] = useState<IRelocateTypesEnum | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [quantity, setQuantity] = useState('');

  const [selectedEquipments, setSelectedEquipments] = useState<string[]>([]);
  const [documentNumber, setDocumentNumber] = useState('');
  const [partnerName, setPartnerName] = useState('');

  const navigate = useHistory();

  const onClose = () => {
    partnersManagementEvent({ isOpenDrawerRelocateEquipment: false, isOpenDrawerDetails: true });
    setSelectedRelocate(null);
  };

  const getDefaultValue = () => {
    const filteredQuestions = relocateDetails?.answers?.find(
      item =>
        item.question.questionType === 'EquipmentQty' &&
        item.question.questionCategory === 'Negotiation',
    );
    const num = Number(filteredQuestions?.answer[0] || 1);
    return num;
  };

  const handleButtonDisabled = () => {
    switch (selectedRelocate) {
      case IRelocateTypesEnum.MATRIX:
        return !quantity || Number(quantity) !== selectedEquipments.length;
      case IRelocateTypesEnum.EXISTING:
        return (
          !quantity ||
          Number(quantity) !== selectedEquipments.length ||
          !documentNumber ||
          !partnerName
        );
      case IRelocateTypesEnum.NEW:
        return !quantity || Number(quantity) !== selectedEquipments.length;
      default:
        return true;
    }
  };

  const onRelocate = () => {
    if (selectedRelocate === IRelocateTypesEnum.MATRIX) {
      setOpenModal(true);
    } else {
      const formattedRequest = Formatter.relocateUpdateAnswers({
        request: relocateDetails,
        documentNumber,
        partnerName,
        quantity,
        relocateType: selectedRelocate,
      });
      partnersManagementEvent({ isOpenDrawerRelocateEquipment: false });
      requestDynamicFormEvent(requestDynamicFormInitialState);
      formDynamicFormEvent({
        ...formDynamicFormInitialState,
        editForm: formattedRequest,
        relocateType: selectedRelocate,
        equipmentsIds: selectedEquipments,
      });
      navigate.push(`/app/update-form/${relocateDetails?.product?.id}`);
    }
  };

  useEffect(() => {
    if (searchPartnerResult?.data?.name) {
      setPartnerName(searchPartnerResult.data.name);
    }
  }, [searchPartnerResult]);

  useEffect(() => {
    setQuantity('');
    setPartnerName('');
    setDocumentNumber('');
    setSelectedEquipments([]);
  }, [isOpenDrawerRelocateEquipment]);

  const getInputMask = () => {
    const questionsAnswers = relocateDetails?.answers?.filter(
      row => row.question.questionCategory !== 'Shipping',
    );
    const isCNPJ = questionsAnswers?.some(row => row.question.questionType === 'CNPJ');
    const isCPF = questionsAnswers?.some(row => row.question.questionType === 'CPF');
    const isCnpjOrCpf = questionsAnswers?.some(row => row.question.questionType === 'CPF_CNPJ');
    if (isCPF) return 'CPF';
    if (isCNPJ) return 'CNPJ';
    if (isCnpjOrCpf) return 'CPF_CNPJ';
    return null;
  };

  return (
    <>
      <Drawer anchor="right" open={isOpenDrawerRelocateEquipment} onClose={onClose}>
        <Stack
          p={4}
          direction="column"
          flex={1}
          sx={{
            width: '698px',
            backgroundColor: 'white',
            paddingBottom: 5,
            borderRadius: '15px 0 0 15px',
          }}
        >
          <Stack mt={2} pb={1} direction="row" justifyContent="space-between" alignItems="center">
            <ButtonBack text="VOLTAR" onBack={onClose} />
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Stack>
          <Typography fontSize={22} fontWeight={700} pb={4}>
            Remanejar equipamento
          </Typography>
          <Typography fontSize={14} fontWeight={700}>
            QUERO REMANEJAR PARA:
          </Typography>
          <Divider />

          <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ mt: 2 }}>
            {[
              {
                id: IRelocateTypesEnum.NEW,
                name: 'NOVA',
              },
              {
                id: IRelocateTypesEnum.EXISTING,
                name: 'EXISTENTE',
              },
              {
                id: IRelocateTypesEnum.MATRIX,
                name: 'MATRIZ',
              },
            ].map(obj => (
              <Button
                key={obj.id}
                variant="contained"
                color={selectedRelocate === obj.id ? 'info' : 'primary'}
                onClick={() => setSelectedRelocate(obj.id)}
              >
                {obj.name}
              </Button>
            ))}
          </ButtonGroup>

          {!!selectedRelocate && (
            <Box mt={3}>
              <Stack direction="row" alignItems="center" gap={1}>
                <div style={{ width: 148 }}>
                  <Select
                    displayEmpty
                    sx={{
                      width: '148px',
                      height: '50px',
                      fontSize: '16px',
                      backgroundColor: 'white',
                      '& .MuiSelect-select': { color: !quantity ? '#ccc' : 'black' },
                    }}
                    renderValue={!quantity ? () => 'Quantidade' : undefined}
                    labelId="demo-simple-select-label"
                    placeholder="Quantidade"
                    id="demo-simple-select"
                    value={quantity}
                    onChange={v => setQuantity(v.target.value?.toString())}
                  >
                    {new Array(getDefaultValue()).fill('').map((_, i) => (
                      <MenuItem value={i + 1}>
                        <Typography fontSize="16px">{i + 1}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {selectedRelocate === IRelocateTypesEnum.EXISTING && (
                  <>
                    <TextInput
                      startAdornment={
                        <>
                          <SearchOutlined />
                          <div style={{ width: 8 }} />
                        </>
                      }
                      placeholder={getInputMask()}
                      mask={getInputMask()}
                      value={documentNumber}
                      onChange={e => {
                        const val = e.target.value;
                        setDocumentNumber(val);
                      }}
                      onBlur={e => {
                        const val = e.target.value;
                        if (val || val.length > 11) searchPartnerByDocumentUseCase.execute(val);
                      }}
                    />
                    <TextInput value={partnerName} placeholder="Nome" disabled />
                  </>
                )}
              </Stack>
              <>
                <Typography fontSize={14} fontWeight={700} mt={3}>
                  Selecione o número de série dos equipamentos a serem remanejados
                </Typography>
                <Divider />
                <Stack>
                  {relocateDetails?.equipmentItems.length ? (
                    <>
                      {relocateDetails?.equipmentItems
                        ?.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber))
                        ?.map(item => {
                          const isChecked = selectedEquipments?.some(id => id === item.id);
                          return (
                            <FormControlLabel
                              key={item.id}
                              control={
                                <Checkbox
                                  checked={isChecked}
                                  onChange={e => {
                                    if (isChecked) {
                                      setSelectedEquipments(oldState =>
                                        [...oldState].filter(id => id !== item.id),
                                      );
                                    } else if (selectedEquipments.length >= Number(quantity)) {
                                      e.stopPropagation();
                                      alertEvent({
                                        open: true,
                                        text: 'Quantidade inválida',
                                        type: 'error',
                                      });
                                    } else {
                                      setSelectedEquipments(oldState => [...oldState, item.id]);
                                    }
                                  }}
                                />
                              }
                              label={
                                <Typography fontSize={14} fontWeight={500}>
                                  {item.serialNumber || 'N/A'}
                                </Typography>
                              }
                            />
                          );
                        })}
                    </>
                  ) : (
                    <Card sx={{ p: 4 }}>
                      <Typography fontSize="14px" textAlign="center">
                        Para realocar um equipamento é necessário primeiro cadastrar o número serial
                      </Typography>
                    </Card>
                  )}
                </Stack>
              </>
            </Box>
          )}
          <Button
            variant="contained"
            sx={{ width: 200, mt: 6 }}
            disabled={handleButtonDisabled()}
            endIcon={<ArrowForwardOutlined color="inherit" />}
            onClick={onRelocate}
          >
            REMANEJAR
          </Button>
        </Stack>
      </Drawer>
      <ConfirmModal
        open={openModal}
        setOpen={v => setOpenModal(v)}
        title="Você está remanejando equipamentos para matriz"
        text="Para sua ciência: Este remanejamento irá passar pela equipe de análise para aprovação ou recusa."
        onConfirm={() => {
          relocationMatrixUseCase
            .execute({
              request: relocateDetails,
              selectedEquipments,
            })
            .then(() => setOpenModal(false));
        }}
        isLoading={isLoadingRelocate}
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
    </>
  );
}

export default DrawerRelocateEquipment;
