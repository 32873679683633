/* eslint-disable @typescript-eslint/no-shadow */
import * as Yup from 'yup';

import { Common } from 'utils';

export const validateSessionUserName = (value: string) => {
  try {
    Yup.string().required('* Campo obrigatório').validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateInputNumber = (value: string) => {
  try {
    Yup.string()
      .test('test-number', '* Campo inválido', x => x && Number(x) > 0)
      .validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateSessionUserEmail = (value: string) => {
  try {
    Yup.string()
      .matches(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        '* E-mail inválido',
      )
      .validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateShippingEmail = (value: string) => {
  try {
    Yup.string()
      .email('* E-mail inválido')
      .matches(
        /^([a-z]){1,}([a-z0-9._-]){1,}([@]){1}([a-z0-9]){2,}([.]){1}([a-z]){2,}([.]?){1}([a-z]?){2,}$/i,
        '* E-mail inválido',
      )
      .validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateSessionUserDistrictNumber = (value: string) => {
  try {
    Yup.string().required('* Campo obrigatório').validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateSessionPhoneNumber = (value: string) => {
  try {
    Yup.string().min(13, '* Preencha um telefone válido').validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateSessionUserSetorNumber = async (value: string) => {
  try {
    Yup.string().required('* Campo obrigatório').validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateClinicName = async (value: string) => {
  try {
    Yup.string().required('* Campo obrigatório').validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateRequestCRMs = (value: any[]) => {
  try {
    Yup.array()
      .required('* Campo obrigatório')
      .test('array-length-CRM', '* Campo obrigatório', arr => arr.length > 0)
      .test('uf-filled', '* Preencha todos os campos', arr => arr.every(item => !!item.uf))
      .test('crm-filled', '* Preencha todos os campos', arr => arr.every(item => !!item.crm))
      .validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateCheckboxes = (value: any[]) => {
  try {
    Yup.array().min(1).validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateRadioForm = (value: any[]) => {
  try {
    Yup.string().required('* Campo obrigatório').validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateCep = (value: string) => {
  try {
    Yup.string().required('* Campo obrigatório').min(9, '* CEP Inválido').validateSync(value);
    return true; // Cep válido
  } catch (error) {
    return error.message; // Retorna a mensagem de erro
  }
};

export const validateCity = (value: string) => {
  try {
    Yup.string().required('* Campo obrigatório').validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateUf = async (value: string) => {
  try {
    Yup.string().required('* Campo obrigatório').validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateLocality = async (value: string) => {
  try {
    Yup.string().required('* Campo obrigatório').validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validatePatientsQty = async (value: number) => {
  try {
    Yup.number().required('* Campo obrigatório').validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateCpfCNPJ = async (value: string) => {
  try {
    Yup.string()
      .test('cpfOrCnpj', 'CPF ou CNPJ inválido', value => {
        const cleanedValue = value.replace(/\D/g, '');
        if (cleanedValue.length === 11) {
          return Common.cpfValidator(cleanedValue);
        }
        if (cleanedValue.length === 14) {
          return Common.cnpjValidator(cleanedValue);
        }
        return false;
      })
      .validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};
export const validateCnpj = async (value: string) => {
  try {
    Yup.string()
      .test('CNPJ-test', '* CNPJ inválido.', value => !!value && Common.cnpjValidator(value))
      .validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};

export const validateShippingCPF = async (value: string) => {
  try {
    Yup.string()
      .test('CPF-test', '* CPF inválido.', value => !!value && Common.cpfValidator(value))
      .validateSync(value);
    return true;
  } catch (error) {
    return error.message;
  }
};
