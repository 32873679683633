/* eslint-disable prettier/prettier */

import api from 'clients/api/Api';

import { getAuthAccount, getAuthSecret } from 'services/HostService/HostService';

import { IAuthResponse, IUserInfoResponse } from 'domains/auth/AuthDomain';

interface IParams {
  username: string;
  password: string;
}

const config = {
  headers: {
    account: getAuthAccount(),
    secret: getAuthSecret(),
    'Content-Type': 'application/json',
  },
};

const generateAccessToken = async (params: IParams) => {
  const { data } = await api.post<IAuthResponse>(
    'representant/Auth/GenerateAccessToken',
    params,
    config,
  );
  return data;
};

const getUserInfo = async token => {
  const { data } = await api.get<IUserInfoResponse>('representant/Auth/UserInfo', {
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

const searchUserBySector = async ({ UserRole, UserSector, Search }) => {
  const { data } = await api.get('representant/Users/UsersByHierarchy', {
    params: {
      UserRole,
      UserSector,
      Search,
    },
    headers: {
      ...config.headers,
    },
  });
  return data;
};

const getUserAccess = async params => {
  const { data } = await api.post('cardio/User', params);
  return data;
};

export const AuthService = {
  getUserAccess,
  searchUserBySector,
  generateAccessToken,
  getUserInfo,
};
