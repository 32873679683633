export const getKeycloakUrl = (): string | undefined => {
  const link = process.env.REACT_APP_KEYCLOAK_URL;
  return link;
};
export const getKeycloakRealm = (): string | undefined => {
  const link = process.env.REACT_APP_KEYCLOAK_REALM;
  return link;
};

export const getKeycloakClientId = (): string | undefined => {
  const link = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
  return link;
};

export const getNodeEnv = (): string | undefined => {
  const link = process.env.NODE_ENV;
  return link;
};

export const getApiHost = (): string | undefined => {
  const link = process.env.REACT_APP_API_HOST;
  return link;
};

export const getAuthAccount = (): string | undefined => {
  const link = process.env.REACT_APP_AUTH_ACCOUNT;
  return link;
};

export const getAuthSecret = (): string | undefined => {
  const link = process.env.REACT_APP_AUTH_SECRET;
  return link;
};
