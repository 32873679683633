import { useState } from 'react';

import { Common } from 'utils';

const getStateValue = () => {
  try {
    const sessionValue = Common.getSessionValue('logCookie');
    if (!sessionValue) return null;
    const parsedData = JSON.parse(sessionValue)?.permission;
    return parsedData;
  } catch (err) {
    return null;
  }
};

function ProtectedRoute({ roles, children }) {
  const [manager] = useState(roles?.includes(getStateValue()));
  if (manager) {
    return children;
  }
}

export default ProtectedRoute;
