export enum StatusEnum {
  InAnalysis = 1,
  Approved = 2,
  Reproved = 3,
  SentSuplyer = 4,
  Active = 5,
  Done = 6,
  SentMatrix = 7,
  BackMatrix = 8,
  Deleted = 0,
  Closed = 11,
  Validity = 12,
}

export interface IStatusRequest {
  statusId: string;
  createdBy: string;
  createdAt: string;
}
export interface StatusType {
  statusCode: string;
}

export const StatusStringEnum = {
  InAnalysis: 'InAnalysis',
  Approved: 'Approved',
  Reproved: 'Reproved',
  SentSuplyer: 'SentSuplyer',
  Active: 'Active',
  Done: 'Done',
  SentMatrix: 'SentMatrix',
  BackMatrix: 'BackMatrix',
  Deleted: 'Deleted',
  Closed: 'Closed',
  Validity: 'Validity',
};
