import { IMedicines } from 'domains/medicines';
import {
  IPartnersManagement,
  IPartnersManagementResponse,
  ISearchPartners,
} from 'domains/partnersManagement/Partners.domain';
import { IRequestDetail } from 'domains/requestManagement';

export interface PartnersManagementState {
  isLoading: boolean;
  isLoadingExport: boolean;
  dataSource: IPartnersManagementResponse | null;
  pageSize: number;
  pageNow: number;
  selectedRow: IPartnersManagement | null;
  isOpenEditValidityModal: boolean;
  filters: {
    StartValidity: string | null;
    EndValidity: string | null;
    Status: string | null;
    ProductId: string | null;
    SectorFilter: string | null;
    MedicineId: string | null;
  };

  // DRAWER PARTNER DETAILS
  isOpenDrawerDetails: boolean;
  isLoadingDetails: boolean;
  requestDetails: IRequestDetail[];

  // DRAWER RELOCATE EQUIPMENT
  relocateDetails: IRequestDetail | null;
  isLoadingRelocate: boolean;
  isOpenDrawerRelocateEquipment: boolean;
  searchPartnerResult: ISearchPartners | null;

  // DRAWER EDIT CRM
  partnerListCRM: string[];
  isLoadingEditCRM: boolean;
  isOpenDrawerEditCrm: boolean;

  // DRAWER EDIT MEDICINE
  listMedicine: IMedicines[] | null;
  partnerListMedicines: string[];
  isLoadingEditMedicine: boolean;
  isOpenDrawerEditMedicine: boolean;

  // DRAWER PARTNER CO-MANAGERS
  isLoadingCoManagers: boolean;
  isOpenDrawerPartnerCoManagers: boolean;
  isOpenDrawerPartnerCoManagersModal: boolean;
  coManagerUserInfo: any;

  // DRAWER PARTNER REPORT QTY
  isLoadingReportQty: boolean;
  isOpenDrawerPartnerReportQty: boolean;
  isOpenDrawerPartnerReportQtyModal: boolean;
  reportQtyNow: string;
  reportQtyRequestId: string;

  // DRAWER PARTNER MANAGER
  isLoadingManager: boolean;
  isOpenDrawerPartnerManager: boolean;
  isOpenDrawerPartnerManagerModal: boolean;
  managerUserInfo: any;

  // DRAWER SERIAL NUMBER
  isOpenDrawerSerialNumber: boolean;
  isLoadingDrawerSerialNumber: boolean;
  detailsDrawerSerialNumber: IRequestDetail | null;
}

export const partnersManagementInitialState: PartnersManagementState = {
  isLoading: false,
  isLoadingExport: false,
  dataSource: null,
  pageSize: 10,
  pageNow: 1,
  selectedRow: null,
  isOpenEditValidityModal: false,
  filters: {
    StartValidity: null,
    EndValidity: null,
    Status: null,
    ProductId: null,
    SectorFilter: null,
    MedicineId: null,
  },

  // DRAWER PARTNER DETAILS
  isOpenDrawerDetails: false,
  isLoadingDetails: false,
  requestDetails: [],

  // DRAWER RELOCATE EQUIPMENT
  relocateDetails: null,
  isOpenDrawerRelocateEquipment: false,
  isLoadingRelocate: false,
  searchPartnerResult: null,

  // DRAWER EDIT CRM
  partnerListCRM: [],
  isLoadingEditCRM: false,
  isOpenDrawerEditCrm: false,

  // DRAWER EDIT MEDICINE
  listMedicine: [],
  partnerListMedicines: [],
  isLoadingEditMedicine: false,
  isOpenDrawerEditMedicine: false,

  // DRAWER PARTNER CO-MANAGERS
  isLoadingCoManagers: false,
  isOpenDrawerPartnerCoManagers: false,
  isOpenDrawerPartnerCoManagersModal: false,
  coManagerUserInfo: null,

  // DRAWER PARTNER MANAGER
  isLoadingManager: false,
  isOpenDrawerPartnerManager: false,
  isOpenDrawerPartnerManagerModal: false,
  managerUserInfo: null,

  // DRAWER SERIAL NUMBER
  isOpenDrawerSerialNumber: false,
  isLoadingDrawerSerialNumber: false,
  detailsDrawerSerialNumber: null,

  // DRAWER PARTNER REPORT QTY
  isLoadingReportQty: false,
  isOpenDrawerPartnerReportQty: false,
  isOpenDrawerPartnerReportQtyModal: false,
  reportQtyNow: '',
  reportQtyRequestId: '',
};
