import { Warning } from '@mui/icons-material';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';

import { Common } from 'utils';

import { StatusStringEnum } from 'domains/status';

interface IProps {
  status: string;
  date?: {
    statusId: string;
    createdAt: string;
  }[];
}
const mapStringToStepIndex = (status: string): number => {
  switch (status) {
    case StatusStringEnum.InAnalysis:
      return 0;
    case StatusStringEnum.Active:
      return 1;
    case StatusStringEnum.Reproved:
      return 1;
    case StatusStringEnum.SentSuplyer:
      return 2;
    case StatusStringEnum.SentMatrix:
      return 2;
    case StatusStringEnum.BackMatrix:
      return 2;
    case StatusStringEnum.Done:
      return 4;
    default:
      return 4;
  }
};
function filterDate(date: IProps['date'], status: string, statusNow: string): string | null {
  const posStatusNow = mapStringToStepIndex(statusNow);
  if (!date) return null;
  const arrDate = date.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  const getStatusDateByStatusId = (statusId: string): string | null => {
    const statusDate = arrDate.find(i => i.statusId === statusId)?.createdAt || null;
    const posStatus = mapStringToStepIndex(statusId);

    return posStatus <= posStatusNow ? Common.formatDate(statusDate) : null;
  };

  switch (status) {
    case `${StatusStringEnum.Approved}|${StatusStringEnum.Reproved}`:
      return (
        getStatusDateByStatusId(StatusStringEnum.Approved) ||
        getStatusDateByStatusId(StatusStringEnum.Reproved)
      );
    case `${StatusStringEnum.Active}|${StatusStringEnum.Done}`:
      return (
        getStatusDateByStatusId(StatusStringEnum.Active) ||
        getStatusDateByStatusId(StatusStringEnum.Done)
      );
    case `${StatusStringEnum.SentSuplyer} | ${StatusStringEnum.SentMatrix} | ${StatusStringEnum.BackMatrix}`:
      return (
        getStatusDateByStatusId(StatusStringEnum.SentSuplyer) ||
        getStatusDateByStatusId(StatusStringEnum.SentMatrix) ||
        getStatusDateByStatusId(StatusStringEnum.BackMatrix)
      );
    case StatusStringEnum.InAnalysis:
      return getStatusDateByStatusId(StatusStringEnum.InAnalysis);
    default:
      return null;
  }
}
StepsStatus.defaultProps = {
  date: null,
};

function StepsStatus({ status, date }: { status: string; date?: IProps['date'] }) {
  const steps = [
    {
      title: 'Solicitação realizada',
      date: filterDate(date, StatusStringEnum.InAnalysis, status) ?? '',
    },
    {
      title: 'Solicitação aprovada',
      date:
        filterDate(date, `${StatusStringEnum.Approved}|${StatusStringEnum.Reproved}`, status) ?? '',
    },
    {
      title: 'Envio realizado',
      date:
        filterDate(
          date,
          `${StatusStringEnum.SentSuplyer} | ${StatusStringEnum.SentMatrix} | ${StatusStringEnum.BackMatrix}`,
          status,
        ) ?? '',
    },
    {
      title: 'Solicitação concluída',
      date: filterDate(date, `${StatusStringEnum.Active}|${StatusStringEnum.Done}`, status) ?? '',
    },
  ];

  const activeStep = mapStringToStepIndex(status);
  return (
    <Box sx={{ width: '100%' }} my={5}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label.title}>
            <StepLabel
              icon={
                index === activeStep && status === 'Reproved' ? (
                  <Warning style={{ color: 'red' }} />
                ) : null
              }
            >
              {index === activeStep && status === 'Reproved' ? (
                <Box>
                  <Typography fontSize="14px" fontWeight="bold" color="red">
                    Reprovado
                  </Typography>
                  <Typography fontSize="14px">{label.date}</Typography>
                </Box>
              ) : (
                <Box>
                  <Typography fontSize="14px" fontWeight="bold">
                    {label.title}
                  </Typography>
                  <Typography fontSize="12px">{label.date}</Typography>
                </Box>
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default StepsStatus;
